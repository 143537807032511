.main-btn-styles{
  width: 186px;
  height: 60px;
  border-radius: 8px;
  position: relative;
}

.primary-btn{
  background-color: #DF4E57;
  position: relative;
  &:hover::before{
    content: "";
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#F5F8FA,.2);
  }
  &:active{
    background-color: #DF4E57;
  }
  &:active::before{
    background-color: transparent;
  }
}

.secondary-btn{
  background-color: transparent;
  border: 1px solid #F5F8FA;
  &:hover{
    color: #020614;
    background-color: #F5F8FA;
    border: none;
  }
  &:active{
    background-color: transparent;
    color: #F5F8FA;
    border: 1px solid #F5F8FA;
  }
}